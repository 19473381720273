@import '../node_modules/bootstrap/scss/bootstrap.scss';

body {
    font-family: 'Poppins', sans-serif;
    background-color: #d8e0f0;
}

nav {
margin-bottom: 50px;
}

.navbar .navbar-nav a{
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: #110003 !important;
}

.rmdp-container {
  width: 100%;
}

.profilephoto {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #d8e0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navright {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.countercontainer {
    padding: 10px;
    background-color: #fff;
}

.tablecontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border-width: 1px;
    border-color: #d8e0f0;
}

.btn-primary {
  background-color: #25A18E;
  border-color: #25A18E;
}

/* Change the primary background color to #25A18E */
.bg-primary {
  background-color: #25A18E;
}

.page-item.active, .active > .page-link {
  background-color: #25A18E !important;
  border-color: #25A18E !important;
}

.tableheader {
    width: 100%;
    height: 44px;
    background-color: #25A18E!important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.tableheader h2 {
    font-size: 22px;
    color: #fff;
    font-weight: bold;
}

.searchbarrow {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

.tableinsidecontainer {
    width: '100%';
}

.tableinsidecontainer th {
    background-color: #0d6efd !important;
    color: #fff
}

.react-bootstrap-table .table {
    text-align: center;
  }
  
  /* Center the table columns */
  .react-bootstrap-table .table td,
  .react-bootstrap-table .table th {
    text-align: center;
  }

  .buttonadd {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000
  }

  .sidebartitle {
    width: 100%;
    height: 112px;
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .sidebarprofile {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .sidebarheadercontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .name-user {
    font-size: 18px;
    color: #252525;
    text-transform: capitalize;
    font-weight: 700;
  }

  .logout {
    color: #EB5757 !important;
    font-size: 14px;
  }

  .viewprofile {
    color: #252525 !important;
    font-size: 14px;
  }

  .changepassword {
    color: #5d6bc4 !important;
    font-size: 14px;
  }

  .modalheader {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8e8e8;
  }

  .modalbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    max-height: 350px;
  }

  .modalbodydelete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-height: 330px;
  }

  .modalbodysuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
  }

  .forminput {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100% !important
  }
  .react-datepicker__input-container input {
    width: 100% !important;
    height: 50px;
    padding-left: 20px;
    border-radius: 5px;
    border-color: #eeeeee;
  }

  .checkinput {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalfooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

  }

  .modalbutton {
    width: 45%;
    height: 50px;
    cursor: 'pointer'
  }

  .formlayout {
    width: 100%;
    margin: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .submitbutton {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .navactions {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .queuetitle {
    font-size: 100px;
    font-weight: bold;
    color: #0080FF;
  }

  .queuecontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
    width: 100%;
    height: calc(100vh - 100px);
  }

  .queuetitleandcat {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttoncat {
    width: 60%;
  }

  .bookinginput {
    width: 60%;
  }

.bottombar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.buttonbottom {
    margin-left: 10px;
    margin-right: 10px;
}

.modalbuttonresults {
    background-color: black;
    color: #fff;
    width: 150px;
    height: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalbodyresult {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ticketname {
    color: #545454;
    font-weight: 700;
}

.queuenumbertitle {
    color: #545454;
    font-weight: 700;
    font-size: 20px;
}

.queuenumber {
    color: #545454;
    font-weight: 700;
    font-size: 22px;
}

.queuetime {
    color: #545454;
    font-weight: 700;
    font-size: 12px;
}

.queuecategory {
    color: #545454;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 50px;
}

.queuewaiting {
    margin-bottom: 30px;
}


.logincontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px;
    width: 100%;
    height: calc(100vh - 100px);
  }

  .quicklinetitle {
    font-size: 50px;
    font-weight: bold;
    color: #0080FF;
  }

  .quicklineminititle {
    font-size: 20px;
    color: #807f84;
  }

  .queuetitleandcat {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttoncat {
    width: 40%;
  }

  .bookinginput {
    width: 40%;
  }

  .tableicon {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .react-bootstrap-table .table td{
    word-wrap: break-word !important;
  }

  .callcontainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .callheader {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
  }

  .callheader h2{
    color: #0080FF;
    padding-left: 20px;
  }

  .callheadercontent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
  }

  .callheaderselectcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 10px;
    padding-left: 10px;
  }

  .callheaderselectcontainer span {
    padding-right: 20px;
    color: #fff;
  }

  .callheadernamecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 10px;
    padding-left: 10px;
  }

  .callheadernamecontainer span {
    color: #fff;
  }

  .callheaderwatchcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding-right: 10px;
    padding-left: 10px;
  }

  .callheaderwatchcontainer span {
    color: #fff;
  }

  .callheaderswitchcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;
    padding-right: 10px;
    padding-left: 10px;
  }

  .callmain {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 20px;
    height: calc(100vh - 200px)
  }

  .currentticketcontainer {
    height: calc(100vh - 200px);
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: #fff; /* Set the background color of the div */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add a slight shadow */
  
  }

  .savingqueue {
    font-size: 25px;
    font-weight: bold;
  }

  .currentsaving {
    font-size: 21px;
    font-weight: normal;
  }

  .savingqueue {
    font-size: 25px;
    font-weight: bold;
  }
  .ticketnumber {
    font-size: 30px;
    font-weight: bold;
  }
  .servingtime {
    font-size: 30px;
    font-weight: bold;
  }
  .timeer {
    font-size: 30px;
    font-weight: bold;
  }
  .service {
    font-size: 35px;
    font-weight: bold;
  }
  .ticketmname {
    font-size: 21px;
    font-weight: bold;
  }

  .mobile {
    font-size: 21px;
    font-weight: bold;
  }
  .countername {
    font-size: 21px;
    font-weight: bold;
  }
  .datename {
    font-size: 21px;
    font-weight: bold;
  }

  .actionscontainer {
    height: calc(100vh - 0px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .callactionbutton {
    width: 100%;
    height: calc(100vh / 6 - 40px);
    margin-bottom: 20px;
  }

  .ticketscontainer {
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff; /* Set the background color of the div */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add a slight shadow */
    overflow: auto;
  }

  .waitingcount {
    background-color: #0080FF;
    color: #fff;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ticket {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ticketservice {
    font-size: 14px;
    color: #000;
  }

  .ticketnameandcode {
    font-size: 20px;
    color: #000;
  }
  .ticketphone {
    font-size: 16px;
    color: #000;
  }
  .ticketdate {
    font-size: 13px;
    color: #000;
  }

.callfooter {
  background-color: #0080FF;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.callfooter span {
  color: #fff;
  padding-left: 20px;
  font-weight: 700;
}

.chartcontainer {
  background-color: #fff;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}